import axios from 'axios';
import { config } from '../../config';
import { batch } from 'react-redux'
import { alertService } from '../../_services';

/**
 * Clients list
 */
export const actionTypes = {
    TRANSACTION_FETCH: 'clientFetch',
    TRANSACTION_SYNC: 'clientSync',
    TRANSACTIONS_SYNC: 'clientsSync',
    AGENTS_SYNC: 'agentsSync',
    TRANSACTION_ERROR: 'clientError',
}

export const pricingOptions = [
    { value: 'supply', label: 'Supply' },
    { value: 'retail', label: 'Retail' },
    { value: 'wholesale', label: 'Wholesale' },
];

export const clientFetch = (isFetching: boolean) => {
    return {
        type: actionTypes.TRANSACTION_FETCH,
        isFetching,
    }
}

export const clientSync = (client: any) => {
    return {
        type: actionTypes.TRANSACTION_SYNC,
        client
    }
}

export const clientsSync = (clients: any[]) => {
    return {
        type: actionTypes.TRANSACTIONS_SYNC,
        clients
    }
}
export const agentsSync = (agents: any[]) => {
    return {
        type: actionTypes.AGENTS_SYNC,
        agents
    }
}

export const clientError = (error: Error) => {
    return {
        type: actionTypes.TRANSACTION_ERROR,
        error,
    }
}

export const clearClient = () => ((dispatch) => {
    dispatch(clientSync(null))
});

export const getClients = () => ((dispatch, getState) => {
    dispatch(clientFetch(true));

    const hconfig = {
        headers: { Authorization: `Bearer ${getState().Auth.token}` }
    }

    axios.get(`${config.apiUrl}/clients`, hconfig)
        .then(res => {
            dispatch(clientsSync(res.data.clients))
        })
        .catch(error => {
            console.log("error", error)
            dispatch(clientError(error.message));
        });
});

export const getClient = (id) => ((dispatch, getState) => {
    dispatch(clientFetch(true));

    const hconfig = {
        headers: { Authorization: `Bearer ${getState().Auth.token}` }
    }

    axios.get(`${config.apiUrl}/clients/${id}`, hconfig)
        .then(res => {
            dispatch(clientSync(res.data))
        })
        .catch(error => {
            console.log("error", error)
            dispatch(clientError(error.message));
        });

});

export const createClient = (data: any, history: any) => ((dispatch, getState) => {
    dispatch(clientFetch(true))

    const hconfig = {
        headers: { Authorization: `Bearer ${getState().Auth.token}` }
    }

    axios.post(`${config.apiUrl}/clients`, data, hconfig)
        .then(res => {
            history.push(`/clients/${res.data.id}`)
            alertService.success("Client successfully saved")

        })
        .catch(error => {
            dispatch(clientFetch(false))
            console.log("error", error)
           if (error.response.data.message) {
                alertService.error('Oops! ' + error.response.data.message, {autoClose: true})
            }
            else {
                alertService.error('Oops! Something went wrong, please try again later.', {autoClose: true})
            }
        });
});

export const updateClient = (id, data) => ((dispatch, getState) => {
    dispatch(clientFetch(true))

    const hconfig = {
        headers: { Authorization: `Bearer ${getState().Auth.token}` }
    }

    axios.put(`${config.apiUrl}/clients/${id}`, data, hconfig)
        .then(res => {
            dispatch(clientSync(res.data))
            alertService.success("Client successfully updated", {autoClose: true})

        })
        .catch(error => {
            dispatch(clientFetch(false))
            console.log("error", error)
            if (error.response.data.message) {
                alertService.error('Oops! ' + error.response.data.message, {autoClose: true})
            }
            else {
                alertService.error('Oops! Something went wrong, please try again later.', {autoClose: true})
            }
        });

});

export const deleteClient = (id, history) => ((dispatch, getState) => {
    dispatch(clientFetch(true))

    const hconfig = {
        headers: { Authorization: `Bearer ${getState().Auth.token}` }
    }

    axios.delete(`${config.apiUrl}/clients/${id}`, hconfig)
        .then(res => {
            history.push(`/clients`)
        })
        .catch(error => {
            dispatch(clientFetch(false))
            console.log("error", error)
            if (error.response.data.message) {
                alertService.error('Oops! ' + error.response.data.message, {autoClose: true})
            }
            else {
                alertService.error('Oops! Something went wrong, please try again later.', {autoClose: true})
            }
        });

});

