import { actionTypes } from './AgentsAction';

const initialState = {
    isFetching: false,
    agent: null,
    agents: [],
    users: [],
    orders: [],
    transactions: [],
    error: null
}

export const agents = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.AGENT_FETCH:
            return {
                ...state,
                isFetching: action.isFetching,
            }
        case actionTypes.AGENT_SYNC:
            return {
                ...state,
                isFetching: false,
                agent: action.agent,
                error: null
            }
        case actionTypes.AGENTS_SYNC:
            return {
                ...state,
                isFetching: false,
                agents: action.agents,
                error: null
            }
        case actionTypes.USERS_SYNC:
            return {
                ...state,
                isFetching: false,
                users: action.users,
                error: null
            }
        case actionTypes.ORDERS_SYNC:
            return {
                ...state,
                isFetching: false,
                orders: action.orders,
                error: null
            }
        case actionTypes.TRANSACTIONS_SYNC:
            return {
                ...state,
                isFetching: false,
                transactions: action.transactions,
                error: null
            }
            
        case actionTypes.AGENT_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
        default:
            return state;
    }
}