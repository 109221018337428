import { actionTypes } from './ReportAction';

const initialState = {
    isFetching: false,
    depostis: [],
    orders: [],
    error: null
}

export const reports = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.REPORT_FETCH:
            return {
                ...state,
                isFetching: action.isFetching,
            }
        
        case actionTypes.ORDERS_SYNC:
            return {
                ...state,
                isFetching: false,
                orders: action.orders,
                error: null
            }
        case actionTypes.TRANSACTIONS_SYNC:
            return {
                ...state,
                isFetching: false,
                transactions: action.transactions,
                error: null
            }
       
        case actionTypes.REPORT_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
        default:
            return state;
    }
}