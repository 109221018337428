import axios from 'axios';
import { config } from '../../config';
import qs from 'querystring'
import { batch } from 'react-redux';
import { getMe } from '../users/UsersAction';
import { alertService } from '../../_services/alert.service';

export const actionTypes = {
    AGENT_FETCH: 'agentFetch',
    AGENT_SYNC: 'agentSync',
    AGENTS_SYNC: 'agentsSync',
    USERS_SYNC: 'usersSync',
    ORDERS_SYNC: 'ordersSync',
    TRANSACTIONS_SYNC: 'transactionsSync',
    AGENT_ERROR: 'agentError',
}

export const userRoles = [
    { value: 'manager', label: 'Manager' },
    { value: 'staff', label: 'Staff' },
];

export const agentFetch = (isFetching: boolean) => {
    return {
        type: actionTypes.AGENT_FETCH,
        isFetching,
    }
}

export const agentSync = (agent: any) => {
    return {
        type: actionTypes.AGENT_SYNC,
        agent
    }
}

export const agentsSync = (agents: any[]) => {
    return {
        type: actionTypes.AGENTS_SYNC,
        agents
    }
}

export const agentUsersSync = (users: any[]) => {
    return {
        type: actionTypes.USERS_SYNC,
        users
    }
}

export const agentOrdersSync = (orders: any[]) => {
    return {
        type: actionTypes.ORDERS_SYNC,
        orders
    }
}

export const agentTransactionsSync = (transactions: any[]) => {
    return {
        type: actionTypes.TRANSACTIONS_SYNC,
        transactions
    }
}

export const agentError = (error: Error) => {
    return {
        type: actionTypes.AGENT_ERROR,
        error,
    }
}

export const clearAgent = () => ((dispatch) => {
    dispatch(agentSync(null))
});

export const getAgents = (filter: any = {}) => ((dispatch, getState) => {
    dispatch(agentFetch(true));

    const hconfig = {
        headers: { Authorization: `Bearer ${getState().Auth.token}` }
    }

    const query = qs.stringify({
        ...filter
    });

    axios.get(`${config.apiUrl}/agents?${query}`, hconfig)
        .then(res => {
            dispatch(agentsSync(res.data.agents))
        })
        .catch(error => {
            console.log("error", error)
            dispatch(agentError(error.message));
        });
});

export const getAgent = (id) => ((dispatch, getState) => {
    dispatch(agentFetch(true));

    const hconfig = {
        headers: { Authorization: `Bearer ${getState().Auth.token}` }
    }

    axios.get(`${config.apiUrl}/agents/${id}`, hconfig)
        .then(res => {
            dispatch(agentSync(res.data))
        })
        .catch(error => {
            console.log("error", error)
            dispatch(agentError(error.message));
        });

});

export const createAgent = (data, history) => ((dispatch, getState) => {
    dispatch(agentFetch(true))

    const hconfig = {
        headers: { Authorization: `Bearer ${getState().Auth.token}` }
    }

    axios.post(`${config.apiUrl}/agents`, data, hconfig)
        .then(res => {
            history.push(`/agents/${res.data.id}`)
        })
        .catch(error => {
            console.log("error", error)
            dispatch(agentError(error.message));

            if (error.response.data.message) {
                alertService.error('Oops! ' + error.response.data.message, {autoClose: true})
            }
            else {
                alertService.error('Oops! Something went wrong, please try again later.', {autoClose: true})
            }
        });
});

export const updateAgent = (id, data) => ((dispatch, getState) => {
    dispatch(agentFetch(true))

    const hconfig = {
        headers: { Authorization: `Bearer ${getState().Auth.token}` }
    }

    axios.put(`${config.apiUrl}/agents/${id}`, data, hconfig)
        .then(res => {
            batch(() => {
                dispatch(agentSync(res.data))
                dispatch(getMe())
                alertService.success("Agent successfully updated", {autoClose: true})

            })
        })
        .catch(error => {
            console.log("error", error)
            dispatch(agentError(error.message));
            if (error.response.data.message) {
                alertService.error('Oops! ' + error.response.data.message, {autoClose: true})
            }
            else {
                alertService.error('Oops! Something went wrong, please try again later.', {autoClose: true})
            }
        });

});

export const deleteAgent = (id, history) => ((dispatch, getState) => {
    dispatch(agentFetch(true))

    const hconfig = {
        headers: { Authorization: `Bearer ${getState().Auth.token}` }
    }

    axios.delete(`${config.apiUrl}/agents/${id}`, hconfig)
        .then(res => {
            history.push(`/agents`)
        })
        .catch(error => {
            console.log("error", error)
            dispatch(agentError(error.message));
            if (error.response.data.message) {
                alertService.error('Oops! ' + error.response.data.message, {autoClose: true})
            }
            else {
                alertService.error('Oops! Something went wrong, please try again later.', {autoClose: true})
            }
        });

});

export const getAgentUsers = (id: string, filter: any = {}) => ((dispatch, getState) => {
    dispatch(agentFetch(true));

    const hconfig = {
        headers: { Authorization: `Bearer ${getState().Auth.token}` }
    }

    const query = qs.stringify({
        ...filter,
        userGroup: "agent"
    });

    axios.get(`${config.apiUrl}/agents/${id}/users?${query}`, hconfig)
        .then(res => {
            dispatch(agentUsersSync(res.data.users))
        })
        .catch(error => {
            console.log("error", error)
            dispatch(agentError(error.message));
        });
});

export const getAgentOrders = (id: string, filter: any = {}) => ((dispatch, getState) => {
    dispatch(agentFetch(true));

    const hconfig = {
        headers: { Authorization: `Bearer ${getState().Auth.token}` }
    }

    const query = qs.stringify({
        ...filter,
        userGroup: "agent"
    });

    axios.get(`${config.apiUrl}/agents/${id}/orders?${query}`, hconfig)
        .then(res => {
            dispatch(agentOrdersSync(res.data.orders))
        })
        .catch(error => {
            console.log("error", error)
            dispatch(agentError(error.message));
        });
});

export const getAgentTransactions = (id: string, filter: any = {}) => ((dispatch, getState) => {
    dispatch(agentFetch(true));

    const hconfig = {
        headers: { Authorization: `Bearer ${getState().Auth.token}` }
    }

    const query = qs.stringify({
        ...filter,
    });

    axios.get(`${config.apiUrl}/agents/${id}/transactions?${query}`, hconfig)
        .then(res => {
            dispatch(agentTransactionsSync(res.data.transactions))
        })
        .catch(error => {
            console.log("error", error)
            dispatch(agentError(error.message));
        });
});



export const createAgentUser = (id, data) => ((dispatch, getState) => {
    dispatch(agentFetch(true))

    const hconfig = {
        headers: { Authorization: `Bearer ${getState().Auth.token}` }
    }

    axios.post(`${config.apiUrl}/agents/${id}/users`, data, hconfig)
        .then(res => {
            batch(() => {
                dispatch(agentFetch(false))
                dispatch(getAgentUsers(id))
            })
        })
        .catch(error => {
            console.log("error", error)
            dispatch(agentError(error.message));
            if (error.response.data.message) {
                alertService.error('Oops! ' + error.response.data.message, {autoClose: true})
            }
            else {
                alertService.error('Oops! Something went wrong, please try again later.', {autoClose: true})
            }
        });
});


export const createTransaction = (id, data: any) => (
    dispatch,
    getState
  ) => {
    dispatch(agentFetch(true));
  
    const hconfig = {
      headers: { Authorization: `Bearer ${getState().Auth.token}` },
    };
  
    axios
      .post(`${config.apiUrl}/transactions`, data, hconfig)
      .then((res) => {
        batch(() => {
            dispatch(agentFetch(false))
            dispatch(getAgentTransactions(id))
            dispatch(getAgent(id))
        })
      })
      .catch((error) => {
        dispatch(agentFetch(false));
        console.log("error", error);
  
        if (error.response.data.message) {
          alertService.error("Oops! " + error.response.data.message, {
            autoClose: true,
          });
        } else {
          alertService.error(
            "Oops! Something went wrong, please try again later.",
            { autoClose: true }
          );
        }
      });
  };



export const deleteAgentUser = (id, userId) => ((dispatch, getState) => {
    dispatch(agentFetch(true))

    const hconfig = {
        headers: { Authorization: `Bearer ${getState().Auth.token}` }
    }

    axios.delete(`${config.apiUrl}/agents/${id}/users/${userId}`, hconfig)
        .then(res => {
            batch(() => {
                dispatch(agentFetch(false))
                dispatch(getAgentUsers(id))
            })
        })
        .catch(error => {
            console.log("error", error)
            dispatch(agentError(error.message));
            if (error.response.data.message) {
                alertService.error('Oops! ' + error.response.data.message, {autoClose: true})
            }
            else {
                alertService.error('Oops! Something went wrong, please try again later.', {autoClose: true})
            }
        });
});

