import axios from 'axios';
import { config } from '../../config';
import { batch } from 'react-redux'
import { alertService } from '../../_services';
/**
 * Institutions list
 */
export const actionTypes = {
    PRODUCT_FETCH: 'institutionFetch',
    PRODUCT_SYNC: 'institutionSync',
    PRODUCTS_SYNC: 'institutionsSync',
    PRODUCT_ERROR: 'institutionError',
}
    

export const institutionFetch = (isFetching: boolean) => {
    return {
        type: actionTypes.PRODUCT_FETCH,
        isFetching,
    }
}

export const institutionSync = (institution: any) => {
    return {
        type: actionTypes.PRODUCT_SYNC,
        institution
    }
}

export const institutionsSync = (institutions: any[]) => {
    return {
        type: actionTypes.PRODUCTS_SYNC,
        institutions
    }
}

export const institutionError = (error: Error) => {
    return {
        type: actionTypes.PRODUCT_ERROR,
        error,
    }
}

export const getInstitutions = () => ((dispatch, getState) => {
    dispatch(institutionFetch(true));

    const hconfig = {
        headers: { Authorization: `Bearer ${getState().Auth.token}` }
    }

    axios.get(`${config.apiUrl}/institutions`, hconfig)
        .then(res => {
            dispatch(institutionsSync(res.data.institutions))
        })
        .catch(error => {
            console.log("error", error)
            dispatch(institutionError(error.message));
        });
});

export const getInstitution = (id) => ((dispatch, getState) => {
    dispatch(institutionFetch(true));

    const hconfig = {
        headers: { Authorization: `Bearer ${getState().Auth.token}` }
    }

    axios.get(`${config.apiUrl}/institutions/${id}`, hconfig)
        .then(res => {
            dispatch(institutionSync(res.data))
        })
        .catch(error => {
            console.log("error", error)
            dispatch(institutionError(error.message));
        });

});

export const createInstitution = (data: any, history: any) => ((dispatch, getState) => {
    dispatch(institutionFetch(true))

    const hconfig = {
        headers: { Authorization: `Bearer ${getState().Auth.token}` }
    }

    axios.post(`${config.apiUrl}/institutions`, data, hconfig)
        .then(res => {
            dispatch(getInstitutions())
        })
        .catch(error => {
            dispatch(institutionFetch(false))
            console.log("error", error)
            if (error.response.data.message) {
                alertService.error('Oops! ' + error.response.data.message, {autoClose: true})
            }
            else {
                alertService.error('Oops! Something went wrong, please try again later.', {autoClose: true})
            }
        });
});

export const updateInstitution = (id, data) => ((dispatch, getState) => {
    dispatch(institutionFetch(true))

    const hconfig = {
        headers: { Authorization: `Bearer ${getState().Auth.token}` }
    }

    axios.put(`${config.apiUrl}/institutions/${id}`, data, hconfig)
        .then(res => {
            dispatch(institutionSync(res.data))
            alertService.success("Institution successfully updated", {autoClose: true})

        })
        .catch(error => {
            dispatch(institutionFetch(false))
            console.log("error", error)
            if (error.response.data.message) {
                alertService.error('Oops! ' + error.response.data.message, {autoClose: true})
            }
            else {
                alertService.error('Oops! Something went wrong, please try again later.', {autoClose: true})
            }
        });

});

export const deleteInstitution = (id, history) => ((dispatch, getState) => {
    dispatch(institutionFetch(true))

    const hconfig = {
        headers: { Authorization: `Bearer ${getState().Auth.token}` }
    }

    axios.delete(`${config.apiUrl}/institutions/${id}`, hconfig)
        .then(res => {
            history.push(`/institutions`)
        })
        .catch(error => {
            dispatch(institutionFetch(false))
            console.log("error", error)
            if (error.response.data.message) {
                alertService.error('Oops! ' + error.response.data.message, {autoClose: true})
            }
            else {
                alertService.error('Oops! Something went wrong, please try again later.', {autoClose: true})
            }
        });

});
