import { actionTypes } from './InstitutionsAction';

const initialState = {
    isFetching: false,
    institution: null,
    institutions: [],
    error: null
}

export const institutions = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.PRODUCT_FETCH:
            return {
                ...state,
                isFetching: action.isFetching,
            }
        case actionTypes.PRODUCT_SYNC:
            return {
                ...state,
                isFetching: false,
                institution: action.institution,
                error: null
            }
        case actionTypes.PRODUCTS_SYNC:
            return {
                ...state,
                isFetching: false,
                institutions: action.institutions,
                error: null
            }
        case actionTypes.PRODUCT_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
        default:
            return state;
    }
}