import axios from 'axios';
import { config } from '../../config';
import qs from 'querystring'

/**
 * Users list
 */
export const actionTypes = {
    AUTOCOMPLETE_FETCH: 'autocompleteFetch',
    CLIENT_SUGGESTION_SYNC: 'clientSuggestionSync',
    RECEIVER_SUGGESTION_SYNC: 'receiverSuggestionSync',
    AGENT_SUGGESTION_SYNC: 'agentSuggestionSync',
    INSTITUTION_SUGGESTION_SYNC: 'institutionSuggestionSync',
    SUPPLIER_SUGGESTION_SYNC: 'supplierSuggestionSync',
    AUTOCOMPLETE_ERROR: 'autocompleteError',
}

export const autocompleteFetch = (isFetching: boolean) => {
    return {
        type: actionTypes.AUTOCOMPLETE_FETCH,
        isFetching,
    }
}

export const clientSuggestionSync = (suggestions: any[]) => {
    return {
        type: actionTypes.CLIENT_SUGGESTION_SYNC,
        suggestions
    }
}

export const agentSuggestionSync = (suggestions: any[]) => {
    return {
        type: actionTypes.AGENT_SUGGESTION_SYNC,
        suggestions
    }
}

export const receiverSuggestionSync = (suggestions: any[]) => {
    return {
        type: actionTypes.RECEIVER_SUGGESTION_SYNC,
        suggestions
    }
}

export const institutionSuggestionSync = (suggestions: any[]) => {
    return {
        type: actionTypes.INSTITUTION_SUGGESTION_SYNC,
        suggestions
    }
}


export const supplierSuggestionSync = (suggestions: any[]) => {
    return {
        type: actionTypes.SUPPLIER_SUGGESTION_SYNC,
        suggestions
    }
}

export const autocompleteError = (error: Error) => {
    return {
        type: actionTypes.AUTOCOMPLETE_ERROR,
        error,
    }
}

export const agentAutocomplete = (query: string) => ((dispatch, getState) => {
    dispatch(autocompleteFetch(true));

    const hconfig = {
        headers: { Authorization: `Bearer ${getState().Auth.token}` }
    }

    const rQuery = qs.stringify({
        query: query
    });

    axios.get(`${config.apiUrl}/agents/autocomplete?${rQuery}`, hconfig)
        .then(res => {
            dispatch(agentSuggestionSync(res.data))
        })
        .catch(error => {
            console.log("error", error)
            dispatch(autocompleteError(error.message));
        });
});

export const clientAutocomplete = (query: string) => ((dispatch, getState) => {
    dispatch(autocompleteFetch(true));

    const hconfig = {
        headers: { Authorization: `Bearer ${getState().Auth.token}` }
    }

    const rQuery = qs.stringify({
        query: query
    });

    axios.get(`${config.apiUrl}/clients/autocomplete?${rQuery}`, hconfig)
        .then(res => {
            dispatch(clientSuggestionSync(res.data))
        })
        .catch(error => {
            console.log("error", error)
            dispatch(autocompleteError(error.message));
        });
});


export const receiverAutocomplete = (clientId: string, query: string) => ((dispatch, getState) => {
    dispatch(autocompleteFetch(true));

    const hconfig = {
        headers: { Authorization: `Bearer ${getState().Auth.token}` }
    }

    const rQuery = qs.stringify({
        query: query
    });

    axios.get(`${config.apiUrl}/clients/${clientId}/receivers/autocomplete?${rQuery}`, hconfig)
        .then(res => {
            dispatch(receiverSuggestionSync(res.data))
        })
        .catch(error => {
            console.log("error", error)
            dispatch(autocompleteError(error.message));
        });
});


export const institutionAutocomplete = (query: string) => ((dispatch, getState) => {
    dispatch(autocompleteFetch(true));

    const hconfig = {
        headers: { Authorization: `Bearer ${getState().Auth.token}` }
    }

    const rQuery = qs.stringify({
        query: query
    });

    axios.get(`${config.apiUrl}/institutions/autocomplete?${rQuery}`, hconfig)
        .then(res => {
            dispatch(institutionSuggestionSync(res.data))
        })
        .catch(error => {
            console.log("error", error)
            dispatch(autocompleteError(error.message));
        });
});

export const supplierAutocomplete = (query: string) => ((dispatch, getState) => {
    dispatch(autocompleteFetch(true));

    const hconfig = {
        headers: { Authorization: `Bearer ${getState().Auth.token}` }
    }

    const rQuery = qs.stringify({
        query: query
    });

    axios.get(`${config.apiUrl}/suppliers/autocomplete?${rQuery}`, hconfig)
        .then(res => {
            dispatch(supplierSuggestionSync(res.data))
        })
        .catch(error => {
            console.log("error", error)
            dispatch(autocompleteError(error.message));
        });
});
