import { actionTypes } from './AutocompleteAction';

const initialState = {
    isFetching: false,
    transaction: null,
    clients: [],
    receivers: [],
    institutions: [],
    suppliers: [],
    agents: [],
    products: [],
    client: null,
    receiver: null,
    error: null
}

export const autocomplete = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.AUTOCOMPLETE_FETCH:
            return {
                ...state,
                isFetching: action.isFetching,
            }
        case actionTypes.CLIENT_SUGGESTION_SYNC:
            return {
                ...state,
                isFetching: false,
                clients: action.suggestions,
                error: null
            }
         case actionTypes.AGENT_SUGGESTION_SYNC:
            return {
                ...state,
                isFetching: false,
                agents: action.suggestions,
                error: null
            }
        case actionTypes.RECEIVER_SUGGESTION_SYNC:
            return {
                ...state,
                isFetching: false,
                receivers: action.suggestions,
                error: null
            }
        case actionTypes.INSTITUTION_SUGGESTION_SYNC:
            return {
                ...state,
                isFetching: false,
                institutions: action.suggestions,
                error: null
            }
        case actionTypes.SUPPLIER_SUGGESTION_SYNC:
            return {
                ...state,
                isFetching: false,
                suppliers: action.suggestions,
                error: null
            }
        case actionTypes.AUTOCOMPLETE_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
        default:
            return state;
    }
}