import { actionTypes } from './UsersAction';

const initialState = {
    isFetching: false,
    user: null,
    users: [],
    error: null
}

export const users = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.USER_FETCH:
            return {
                ...state,
                isFetching: action.isFetching,
            }
        case actionTypes.USER_SYNC:
            return {
                ...state,
                isFetching: false,
                user: action.user,
                error: null
            }
        case actionTypes.USERS_SYNC:
            return {
                ...state,
                isFetching: false,
                users: action.users,
                error: null
            }
        case actionTypes.USER_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
        default:
            return state;
    }
}