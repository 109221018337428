import { actionTypes } from "./AuthAction";

const initialState = {
    isFetching: false,
    token: null,
    isAuthenticated: false,
    user: null,
    permissions: [],
    redirectUrl: "/app"
};

const Auth = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_FETCH:
            return {
                ...state,
                isFetching: action.isFetching
            };
        case actionTypes.LOGIN_SYNC:
            return {
                ...state,
                isFetching: false,
                token: action.token,
                isAuthenticated: true,
                user: action.user,
                permissions: []
            };
        case actionTypes.AUTH_USER_SYNC:
            return {
                ...state,
                isFetching: false,
                user: action.user
            };
        case actionTypes.LOGOUT_SYNC:
            return {
                ...state,
                isFetching: false,
                token: null,
                permissions: [],
                isAuthenticated: false,
                user: null,
            };
        case actionTypes.SET_REDIRECT_URL:
            return {
                ...state,
                redirectUrl: action.redirectUrl
            };
        default:
            return state;
    }
};

export default Auth;
