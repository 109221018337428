import axios from "axios";
import { config } from "../../config";
import { batch } from "react-redux";
import { alertService } from "../../_services";
/**
 * Warehouses list
 */

export enum StockEntryType {
  ADD = "add",
  ADJUST = "adjust",
  REMOVE = "remove",
  MOVE = "move"
}

export const actionTypes = {
  WAREHOUSE_FETCH: "warehouseFetch",
  WAREHOUSE_SYNC: "warehouseSync",
  WAREHOUSES_SYNC: "warehousesSync",
  PRODUCTS_SYNC: "productsSync",
  STOCKENTRIES_SYNC: "stockEntriesSync",
  LOTS_SYNC: "lotsSync",
  INVENTORY_SYNC: "inventorySync",
  WAREHOUSE_ERROR: "warehouseError",
};

export const warehouseFetch = (isFetching: boolean) => {
  return {
    type: actionTypes.WAREHOUSE_FETCH,
    isFetching,
  };
};

export const warehouseSync = (warehouse: any) => {
  return {
    type: actionTypes.WAREHOUSE_SYNC,
    warehouse,
  };
};

export const warehousesSync = (warehouses: any[]) => {
  return {
    type: actionTypes.WAREHOUSES_SYNC,
    warehouses,
  };
};

export const stockEntriesSync = (stockEntries: any[]) => {
  return {
    type: actionTypes.STOCKENTRIES_SYNC,
    stockEntries,
  };
};

export const lotsSync = (lots: any[]) => {
  return {
    type: actionTypes.LOTS_SYNC,
    lots,
  };
};
export const inventorySync = (inventory: any[]) => {
  return {
    type: actionTypes.INVENTORY_SYNC,
    inventory,
  };
};

export const productsSync = (products: any[]) => {
  return {
    type: actionTypes.PRODUCTS_SYNC,
    products,
  };
};

export const warehouseError = (error: Error) => {
  return {
    type: actionTypes.WAREHOUSE_ERROR,
    error,
  };
};

export const clearWarehouse = () => (dispatch) => {
  dispatch(warehouseSync(null));
};

export const getWarehouses = () => (dispatch, getState) => {
  dispatch(warehouseFetch(true));

  const hconfig = {
    headers: { Authorization: `Bearer ${getState().Auth.token}` },
  };

  axios
    .get(`${config.apiUrl}/warehouses`, hconfig)
    .then((res) => {
      dispatch(warehousesSync(res.data.warehouses));
    })
    .catch((error) => {
      console.log("error", error);
      dispatch(warehouseError(error.message));
    });
};

export const getWarehouse = (id) => (dispatch, getState) => {
  dispatch(warehouseFetch(true));

  const hconfig = {
    headers: { Authorization: `Bearer ${getState().Auth.token}` },
  };

  axios
    .get(`${config.apiUrl}/warehouses/${id}`, hconfig)
    .then((res) => {
      dispatch(warehouseSync(res.data));
    })
    .catch((error) => {
      console.log("error", error);
      dispatch(warehouseError(error.message));
    });
};

export const createWarehouse = (data: any, history: any) => (
  dispatch,
  getState
) => {
  dispatch(warehouseFetch(true));

  const hconfig = {
    headers: { Authorization: `Bearer ${getState().Auth.token}` },
  };

  axios
    .post(`${config.apiUrl}/warehouses`, data, hconfig)
    .then((res) => {
      history.push(`/warehouses/${res.data.id}`);
    })
    .catch((error) => {
      dispatch(warehouseFetch(false));
      console.log("error", error);

      if (error.response.data.message) {
        alertService.error("Oops! " + error.response.data.message, {
          autoClose: true,
        });
      } else {
        alertService.error(
          "Oops! Something went wrong, please try again later.",
          { autoClose: true }
        );
      }
    });
};

export const updateWarehouse = (id, data) => (dispatch, getState) => {
  dispatch(warehouseFetch(true));

  const hconfig = {
    headers: { Authorization: `Bearer ${getState().Auth.token}` },
  };

  axios
    .put(`${config.apiUrl}/warehouses/${id}`, data, hconfig)
    .then((res) => {
      dispatch(warehouseSync(res.data));
      alertService.success("Warehouse successfully updated", {
        autoClose: true,
      });
    })
    .catch((error) => {
      dispatch(warehouseFetch(false));
      console.log("error", error);

      if (error.response.data.message) {
        alertService.error("Oops! " + error.response.data.message, {
          autoClose: true,
        });
      } else {
        alertService.error(
          "Oops! Something went wrong, please try again later.",
          { autoClose: true }
        );
      }
    });
};

export const deleteWarehouse = (id, history) => (dispatch, getState) => {
  dispatch(warehouseFetch(true));

  const hconfig = {
    headers: { Authorization: `Bearer ${getState().Auth.token}` },
  };

  axios
    .delete(`${config.apiUrl}/warehouses/${id}`, hconfig)
    .then((res) => {
      history.push(`/warehouses`);
    })
    .catch((error) => {
      dispatch(warehouseFetch(false));
      console.log("error", error);

      if (error.response.data.message) {
        alertService.error("Oops! " + error.response.data.message, {
          autoClose: true,
        });
      } else {
        alertService.error(
          "Oops! Something went wrong, please try again later.",
          { autoClose: true }
        );
      }
    });
};

export const getStockEntries = (warehouseId: string) => (
  dispatch,
  getState
) => {
  dispatch(warehouseFetch(true));

  const hconfig = {
    headers: { Authorization: `Bearer ${getState().Auth.token}` },
  };

  axios
    .get(`${config.apiUrl}/warehouses/${warehouseId}/stockentries`, hconfig)
    .then((res) => {
      dispatch(stockEntriesSync(res.data.stockEntries));
    })
    .catch((error) => {
      console.log("error", error);
      dispatch(warehouseError(error.message));
    });
};

export const createStockEntry = (
  warehouseId: string,
  data: any,
  history: any
) => (dispatch, getState) => {
  dispatch(warehouseFetch(true));

  const hconfig = {
    headers: { Authorization: `Bearer ${getState().Auth.token}` },
  };

  axios
    .post(
      `${config.apiUrl}/warehouses/${warehouseId}/stockentries`,
      data,
      hconfig
    )
    .then((res) => {
      dispatch(getWarehouse(warehouseId));
      dispatch(getStockEntries(warehouseId));
      dispatch(getLots(warehouseId));
      dispatch(getInventory(warehouseId));
      alertService.success("Stock entry saved successfully", {
        autoClose: true,
      });
    })
    .catch((error) => {
      dispatch(warehouseFetch(false));
      console.log("error", error);

      if (error.response.data.message) {
        alertService.error("Oops! " + error.response.data.message, {
          autoClose: true,
        });
      } else {
        alertService.error(
          "Oops! Something went wrong, please try again later.",
          { autoClose: true }
        );
      }
    });
};

export const getProducts = () => (dispatch, getState) => {
  dispatch(warehouseFetch(true));

  const hconfig = {
    headers: { Authorization: `Bearer ${getState().Auth.token}` },
  };

  axios
    .get(`${config.apiUrl}/products`, hconfig)
    .then((res) => {
      dispatch(productsSync(res.data.products));
    })
    .catch((error) => {
      console.log("error", error);
      dispatch(warehouseError(error.message));
    });
};

export const getLots = (warehouseId: string) => (dispatch, getState) => {
  dispatch(warehouseFetch(true));

  const hconfig = {
    headers: { Authorization: `Bearer ${getState().Auth.token}` },
  };

  axios
    .get(`${config.apiUrl}/warehouses/${warehouseId}/lots`, hconfig)
    .then((res) => {
      dispatch(lotsSync(res.data));
    })
    .catch((error) => {
      console.log("error", error);
      dispatch(warehouseError(error.message));
    });
};

export const createLot = (warehouseId: string, data: any, history: any) => (
  dispatch,
  getState
) => {
  dispatch(warehouseFetch(true));

  const hconfig = {
    headers: { Authorization: `Bearer ${getState().Auth.token}` },
  };

  axios
    .post(`${config.apiUrl}/warehouses/${warehouseId}/lots`, data, hconfig)
    .then((res) => {
      dispatch(getLots(warehouseId));
    })
    .catch((error) => {
      dispatch(warehouseFetch(false));
      console.log("error", error);

      if (error.response.data.message) {
        alertService.error("Oops! " + error.response.data.message, {
          autoClose: true,
        });
      } else {
        alertService.error(
          "Oops! Something went wrong, please try again later.",
          { autoClose: true }
        );
      }
    });
};

export const updateLot = (warehouseId, id, data) => (dispatch, getState) => {
  dispatch(warehouseFetch(true));

  const hconfig = {
    headers: { Authorization: `Bearer ${getState().Auth.token}` },
  };

  axios
    .put(`${config.apiUrl}/warehouses/${warehouseId}/lots/${id}`, data, hconfig)
    .then((res) => {
      dispatch(getLots(warehouseId));
      alertService.success("Lot successfully updated", { autoClose: true });
    })
    .catch((error) => {
      dispatch(warehouseFetch(false));
      console.log("error", error);

      if (error.response.data.message) {
        alertService.error("Oops! " + error.response.data.message, {
          autoClose: true,
        });
      } else {
        alertService.error(
          "Oops! Something went wrong, please try again later.",
          { autoClose: true }
        );
      }
    });
};

export const deleteLot = (warehouseId, id, history) => (dispatch, getState) => {
  dispatch(warehouseFetch(true));

  const hconfig = {
    headers: { Authorization: `Bearer ${getState().Auth.token}` },
  };

  axios
    .delete(`${config.apiUrl}/warehouses/${warehouseId}/lots/${id}`, hconfig)
    .then((res) => {
      dispatch(getLots(warehouseId));
    })
    .catch((error) => {
      dispatch(warehouseFetch(false));
      console.log("error", error);

      if (error.response.data.message) {
        alertService.error("Oops! " + error.response.data.message, {
          autoClose: true,
        });
      } else {
        alertService.error(
          "Oops! Something went wrong, please try again later.",
          { autoClose: true }
        );
      }
    });
};

export const getInventory = (warehouseId: string) => (dispatch, getState) => {
  dispatch(warehouseFetch(true));

  const hconfig = {
    headers: { Authorization: `Bearer ${getState().Auth.token}` },
  };

  axios
    .get(`${config.apiUrl}/warehouses/${warehouseId}/inventory`, hconfig)
    .then((res) => {
      dispatch(inventorySync(res.data));
    })
    .catch((error) => {
      console.log("error", error);
      dispatch(warehouseError(error.message));
    });
};
