import { actionTypes } from './PurchaseOrdersAction';

const initialState = {
    isFetching: false,
    purchaseOrder: null,
    purchaseOrders: [],
    products: [],
    suppliers: [],
    error: null
}

export const purchaseOrders = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.ORDER_FETCH:
            return {
                ...state,
                isFetching: action.isFetching,
            }
        case actionTypes.ORDER_SYNC:
            return {
                ...state,
                isFetching: false,
                purchaseOrder: action.purchaseOrder,
                error: null
            }
        case actionTypes.ORDERS_SYNC:
            return {
                ...state,
                isFetching: false,
                purchaseOrders: action.purchaseOrders,
                error: null
            }
        case actionTypes.PRODUCTS_SYNC:
            return {
                ...state,
                isFetching: false,
                products: action.products,
                error: null
            }
        case actionTypes.SUPPLIERS_SYNC:
            return {
                ...state,
                isFetching: false,
                suppliers: action.suppliers,
                error: null
            }
        case actionTypes.ORDER_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
        default:
            return state;
    }
}