import { actionTypes } from './SuppliersAction';

const initialState = {
    isFetching: false,
    supplier: null,
    suppliers: [],
    error: null
}

export const suppliers = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.PRODUCT_FETCH:
            return {
                ...state,
                isFetching: action.isFetching,
            }
        case actionTypes.PRODUCT_SYNC:
            return {
                ...state,
                isFetching: false,
                supplier: action.supplier,
                error: null
            }
        case actionTypes.PRODUCTS_SYNC:
            return {
                ...state,
                isFetching: false,
                suppliers: action.suppliers,
                error: null
            }
        case actionTypes.PRODUCT_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
        default:
            return state;
    }
}