import axios from 'axios';
import { config } from '../../config';
import qs from 'querystring';

/**
 * Users list
 */
export const actionTypes = {
    REPORT_FETCH: 'reportFetch',
    ORDERS_SYNC: 'ordersSync',
    TRANSACTIONS_SYNC: 'transactionsSync',
    REPORT_ERROR: 'reportError',
}

export const reportFetch = (isFetching: boolean) => {
    return {
        type: actionTypes.REPORT_FETCH,
        isFetching,
    }
}

export const reportError = (error: Error) => {
    return {
        type: actionTypes.REPORT_ERROR,
        error,
    }
}

export const ordersSync = (orders: any) => {
    return {
        type: actionTypes.ORDERS_SYNC,
        orders
    }
}

export const transactionsSync = (transactions: any) => {
    return {
        type: actionTypes.TRANSACTIONS_SYNC,
        transactions
    }
}


export const getOrders = (filter:any = {}) => ((dispatch, getState) => {
    dispatch(reportFetch(true));

    const hconfig = {
        headers: { Authorization: `Bearer ${getState().Auth.token}` }
    }

    const query = qs.stringify(filter);


    axios.get(`${config.apiUrl}/reports/orders?${query}`, hconfig)
        .then(res => {
            dispatch(ordersSync(res.data))
        })
        .catch(error => {
            console.log("error", error)
            dispatch(reportError(error.message));
        });
});


export const getTransactions = (filter: any = {}) => ((dispatch, getState) => {
    dispatch(reportFetch(true));

    const hconfig = {
        headers: { Authorization: `Bearer ${getState().Auth.token}` }
    }

    const query = qs.stringify(filter);


    axios.get(`${config.apiUrl}/reports/transactions?${query}`, hconfig)
        .then(res => {
            dispatch(transactionsSync(res.data))
        })
        .catch(error => {
            console.log("error", error)
            dispatch(reportError(error.message));
        });
});



