import { actionTypes } from './TransactionsAction';

const initialState = {
    isFetching: false,
    transaction: null,
    transactions: [],
    error: null
}

export const transactions = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.TRANSACTION_FETCH:
            return {
                ...state,
                isFetching: action.isFetching,
            }
        case actionTypes.TRANSACTION_SYNC:
            return {
                ...state,
                isFetching: false,
                transaction: action.transaction,
                error: null
            }
        case actionTypes.TRANSACTIONS_SYNC:
            return {
                ...state,
                isFetching: false,
                transactions: action.transactions,
                error: null
            }
        case actionTypes.TRANSACTION_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
        default:
            return state;
    }
}