import { createStore, combineReducers, applyMiddleware } from 'redux'
import Auth from './views/login/AuthReducer'
import { users } from './views/users/UsersReducer'
import { agents } from './views/agents/AgentsReducer'
import { transactions } from './views/transactions/TransactionsReducer'
import { clients } from './views/clients/ClientReducer'
import { products } from './views/products/ProductsReducer'
import { suppliers } from './views/suppliers/SuppliersReducer'
import { orders } from './views/orders/OrdersReducer'
import { purchaseOrders } from './views/purchaseOrders/PurchaseOrdersReducer'
import { institutions } from './views/institutions/InstitutionsReducer'

import { warehouses } from './views/warehouses/WarehousesReducer'

import { reports } from './views/reports/ReportReducer'
import { dashboard } from './views/dashboard/DashboardReducer'

import { autocomplete } from './components/autocomplete/AutocompleteReducer'


import thunk from 'redux-thunk';
import { loadState, saveState } from './utils';
import { TypedUseSelectorHook, useSelector } from 'react-redux';

type state = {
  appState: {
    sidebarShow: 'responsive' | boolean,
    asideShow: boolean, 
    darkMode: boolean
  }
}

const initialState = {
    sidebarShow: 'responsive',
    asideShow: false,
    darkMode: true,
}

type args = { type?: string, [key: string]: any }

const appState = (state = initialState, { type, ...rest }: args) => {
  switch (type) {
    case 'set':
      return {...state, ...rest }
    default:
      return state
  }
}

export const useTypedSelector: TypedUseSelectorHook<state> = useSelector


const reducers = combineReducers({
  appState,
  Auth,
  users,
  agents,
  clients,
  transactions,
  suppliers,
  products,
  orders,
  purchaseOrders,
  institutions,
  reports,
  warehouses,
  autocomplete,
  dashboard
})
let persistedState = loadState();

const store = createStore(reducers, persistedState, applyMiddleware(thunk))
store.subscribe(() => {
    saveState({
        Auth: store.getState().Auth
    })
});

export default store

