import { actionTypes } from './ClientsAction';

const initialState = {
    isFetching: false,
    client: null,
    clients: [],
    agents: [],
    error: null
}

export const clients = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.TRANSACTION_FETCH:
            return {
                ...state,
                isFetching: action.isFetching,
            }
        case actionTypes.TRANSACTION_SYNC:
            return {
                ...state,
                isFetching: false,
                client: action.client,
                error: null
            }
        case actionTypes.TRANSACTIONS_SYNC:
            return {
                ...state,
                isFetching: false,
                clients: action.clients,
                error: null
            }
        case actionTypes.AGENTS_SYNC:
            return {
                ...state,
                isFetching: false,
                agents: action.agents,
                error: null
            }
        case actionTypes.TRANSACTION_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
        default:
            return state;
    }
}