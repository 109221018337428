export const logoNegative = ['716.5 188.28', `
  <title>expresslogo</title>
<title>distro-app-logo-white</title>
  <g>
    <path d="M304,69a2.21,2.21,0,0,0-2.13-.34l-48,16.66-35.1,12.89a16,16,0,0,1-10.5.23l-83.1-30.78a1.82,1.82,0,0,0-.65-.12H79.14a5.68,5.68,0,0,0-5.38,5.94V76.4a5.67,5.67,0,0,0,5.38,5.93h29.1a8.73,8.73,0,0,1,8.51,8.41c.24,5.13-3.46,9.37-8,9.37H52.25A5.66,5.66,0,0,0,46.88,106v3a5.66,5.66,0,0,0,5.37,5.92H119a8.73,8.73,0,0,1,8.51,8.41c.24,5.13-3.46,9.37-8,9.37H60.31a5.66,5.66,0,0,0-5.37,5.92v3a5.65,5.65,0,0,0,5.36,5.91H94.8a8.73,8.73,0,0,1,8.51,8.41c.24,5.13-3.46,9.37-8,9.37H84.52a5.68,5.68,0,0,0-5.39,5.94v3a5.66,5.66,0,0,0,5.37,5.92h51a13.5,13.5,0,0,1,2.69.28,13.65,13.65,0,0,1,2.61.83l69.6,27.18a7.34,7.34,0,0,0,6.15-.13l39.68-19.14L301.31,170a6,6,0,0,0,2.58-2.45,6.9,6.9,0,0,0,.9-3.61l.12-46L305,71.07A2.7,2.7,0,0,0,304,69Z" transform="translate(-25.37 -20.72)" style="fill: #fff"/>
    <rect x="8.06" y="132.27" width="40.32" height="14.81" rx="6.31" style="fill: #fff"/>
    <rect y="97.17" width="13.44" height="14.81" rx="6.31" style="fill: #fff"/>
    <rect x="8.06" y="46.8" width="24.19" height="14.81" rx="6.31" style="fill: #fff"/>
    <path d="M128.12,56.85,211.51,87.4a7.4,7.4,0,0,0,4.85,0l82.57-28.17a1.34,1.34,0,0,0,.08-2.45L216.33,21.3a7.4,7.4,0,0,0-5.64-.05L128.08,54.37A1.36,1.36,0,0,0,128.12,56.85Z" transform="translate(-25.37 -20.72)" style="fill: #fff"/>
  </g>
  <g>
    <path d="M372.64,65.39a54,54,0,0,1,10.3.78,51.09,51.09,0,0,1,41.55,50.32A50.94,50.94,0,0,1,398.85,161a49.44,49.44,0,0,1-25.15,6.34H338.75V65.39Zm1.28,86.88a34.43,34.43,0,0,0,18.25-4.7,36.34,36.34,0,0,0,9.59-53.67A35.17,35.17,0,0,0,382,81.21a37.06,37.06,0,0,0-8.59-.78H353.74v71.84Z" transform="translate(-25.37 -20.72)" style="fill: #fff"/>
    <path d="M452.69,58.54a8.23,8.23,0,0,1,2.56,6.13,8.63,8.63,0,0,1-17.26,0,8.23,8.23,0,0,1,2.56-6.13,8.48,8.48,0,0,1,12.14,0Zm1.85,34.15v74.62H438.7V92.69Z" transform="translate(-25.37 -20.72)" style="fill: #fff"/>
    <path d="M525,95.61q8.73,4.92,11.43,12.83a30.79,30.79,0,0,1,.93,8.84h-16.7q-.06-6.56-5-9.2Q511.94,106,504.41,106t-12.15,2.5q-4.26,2.35-4.26,5.84,0,4.64,7.95,6.56.29,0,17.55,2.64Q525.29,125.34,531,129q8,5.13,8,15.18,0,9.84-10.3,16.82t-24.86,7q-13.29,0-23.3-6T469,147.36a42.17,42.17,0,0,1-.28-6.13h17.12q.21,11.25,17.54,11.26a30.6,30.6,0,0,0,13.07-2.5q5.26-2.42,5.26-5.84,0-5.35-7.89-7.06-.35-.14-17.26-1.92-11.71-1.21-17.33-4.85-7.89-5.05-7.88-16,0-10.05,9-16.82,9.17-7,23.51-7A42,42,0,0,1,525,95.61Z" transform="translate(-25.37 -20.72)" style="fill: #fff"/>
    <path d="M596.89,168a38.89,38.89,0,0,1-38.57-38.49V103.31h-7.88V92.69h7.88V53h15.91V92.69h20.39v10.62H574.23V129h.07v.28a21.91,21.91,0,0,0,6.61,16,21.6,21.6,0,0,0,16,6.7Z" transform="translate(-25.37 -20.72)" style="fill: #fff"/>
    <path d="M653.29,90.76V107a26.61,26.61,0,0,0-4.26-.35,22.6,22.6,0,0,0-22.09,18.17,28.15,28.15,0,0,0-.64,5.78v36.7H610.39V130.46a43,43,0,0,1,.36-6.13A38.58,38.58,0,0,1,649,90.48,32.08,32.08,0,0,1,653.29,90.76Z" transform="translate(-25.37 -20.72)" style="fill: #fff"/>
    <path d="M703.3,168a38.77,38.77,0,0,1-38.64-38.78,38.61,38.61,0,1,1,65.92,27.37A37,37,0,0,1,703.3,168Zm16-22.67a22.79,22.79,0,0,0,0-32.22,22.67,22.67,0,0,0-32,0,22.74,22.74,0,0,0,16,38.85A21.76,21.76,0,0,0,719.29,145.36Z" transform="translate(-25.37 -20.72)" style="fill: #fff"/>
  </g>
`]
