import axios from "axios";
import { config } from "../../config";
import { batch } from "react-redux";
import qs from "querystring";
import { alertService } from "../../_services";
/**
 * Transactions list
 */
export const actionTypes = {
  TRANSACTION_FETCH: "transactionFetch",
  TRANSACTION_SYNC: "transactionSync",
  TRANSACTIONS_SYNC: "transactionsSync",
  AGENTS_SYNC: "agentsSync",
  TRANSACTION_ERROR: "transactionError",
};

export const paymentMethodOptions = [
  { value: "cash", label: "Cash" },
  { value: "check", label: "Check" },
  { value: "wire", label: "Electronic transfer" },
  { value: "bank", label: "Bank deposit" },
  { value: "mobile", label: "Mobile Money" },
];

export const transactionTypeOptions = [
  { value: "order", label: "Order payment" },
  { value: "agent", label: "Agent Expenses" },
  { value: "expense", label: "General Expense" },
];

export const transactionStatusOptions = [
  { value: "created", label: "Unprocessed" },
  { value: "pending", label: "Pending" },
  { value: "completed", label: "Completed" },
  { value: "canceled", label: "Canceled" },
];

// export const currencyOptions = [
//   { value: "USD", label: "United States Dollar" },
//   { value: "GNF", label: "Guinean Franc" },
//   { value: "XOF", label: "West African CFA" },
// ];

export const transactionFetch = (isFetching: boolean) => {
  return {
    type: actionTypes.TRANSACTION_FETCH,
    isFetching,
  };
};

export const transactionSync = (transaction: any) => {
  return {
    type: actionTypes.TRANSACTION_SYNC,
    transaction,
  };
};

export const transactionsSync = (transactions: any[]) => {
  return {
    type: actionTypes.TRANSACTIONS_SYNC,
    transactions,
  };
};

export const transactionError = (error: Error) => {
  return {
    type: actionTypes.TRANSACTION_ERROR,
    error,
  };
};

export const clearTransaction = () => (dispatch) => {
  dispatch(transactionSync(null));
};

export const getTransactions = (filter) => (dispatch, getState) => {
  dispatch(transactionFetch(true));

  const hconfig = {
    headers: { Authorization: `Bearer ${getState().Auth.token}` },
  };

  const query = qs.stringify(filter);

  axios
    .get(`${config.apiUrl}/transactions?${query}`, hconfig)
    .then((res) => {
      dispatch(transactionsSync(res.data.transactions));
    })
    .catch((error) => {
      console.log("error", error);
      dispatch(transactionError(error.message));
    });
};

export const getTransaction = (id) => (dispatch, getState) => {
  dispatch(transactionFetch(true));

  const hconfig = {
    headers: { Authorization: `Bearer ${getState().Auth.token}` },
  };

  axios
    .get(`${config.apiUrl}/transactions/${id}`, hconfig)
    .then((res) => {
      dispatch(transactionSync(res.data));
    })
    .catch((error) => {
      console.log("error", error);
      dispatch(transactionError(error.message));
    });
};

export const createTransaction = (data: any, history: any) => (
  dispatch,
  getState
) => {
  dispatch(transactionFetch(true));

  const hconfig = {
    headers: { Authorization: `Bearer ${getState().Auth.token}` },
  };

  axios
    .post(`${config.apiUrl}/transactions`, data, hconfig)
    .then((res) => {
      history.push(`/transactions/${res.data.id}`);
    })
    .catch((error) => {
      dispatch(transactionFetch(false));
      console.log("error", error);

      if (error.response.data.message) {
        alertService.error("Oops! " + error.response.data.message, {
          autoClose: true,
        });
      } else {
        alertService.error(
          "Oops! Something went wrong, please try again later.",
          { autoClose: true }
        );
      }
    });
};

export const updateTransaction = (id, data) => (dispatch, getState) => {
  dispatch(transactionFetch(true));

  const hconfig = {
    headers: { Authorization: `Bearer ${getState().Auth.token}` },
  };

  axios
    .put(`${config.apiUrl}/transactions/${id}`, data, hconfig)
    .then((res) => {
      dispatch(transactionSync(res.data));
      alertService.success("Transaction successfully updated", {
        autoClose: true,
      });
    })
    .catch((error) => {
      dispatch(transactionFetch(false));
      console.log("error", error);

      if (error.response.data.message) {
        alertService.error("Oops! " + error.response.data.message, {
          autoClose: true,
        });
      } else {
        alertService.error(
          "Oops! Something went wrong, please try again later.",
          { autoClose: true }
        );
      }
    });
};

export const deleteTransaction = (id, history) => (dispatch, getState) => {
  dispatch(transactionFetch(true));

  const hconfig = {
    headers: { Authorization: `Bearer ${getState().Auth.token}` },
  };

  axios
    .delete(`${config.apiUrl}/transactions/${id}`, hconfig)
    .then((res) => {
      history.push(`/transactions`);
    })
    .catch((error) => {
      dispatch(transactionFetch(false));
      console.log("error", error);

      if (error.response.data.message) {
        alertService.error("Oops! " + error.response.data.message, {
          autoClose: true,
        });
      } else {
        alertService.error(
          "Oops! Something went wrong, please try again later.",
          { autoClose: true }
        );
      }
    });
};

export const updateStatus = (id, status) => (dispatch, getState) => {
  dispatch(transactionFetch(true));

  const hconfig = {
    headers: { Authorization: `Bearer ${getState().Auth.token}` },
  };

  axios
    .put(`${config.apiUrl}/transactions/${id}/status`, { status: status }, hconfig)
    .then((res) => {
      dispatch(transactionSync(res.data));
      alertService.success(`Transactions successfully ${status}`, { autoClose: true });
    })
    .catch((error) => {
      dispatch(transactionFetch(false));
      console.log("error", error);

      if (error.response.data.message) {
        alertService.error("Oops! " + error.response.data.message, {
          autoClose: true,
        });
      } else {
        alertService.error(
          "Oops! Something went wrong, please try again later.",
          { autoClose: true }
        );
      }
    });
};
