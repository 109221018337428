// export const config = {
//   apiUrl: 'http://localhost:3010',
//   headers: {
//     'Content-Type': 'application/json'
//   }
// }

// export const config = {
//   apiUrl: 'https://api.distro.exeapps.com',
//   headers: {
//     'Content-Type': 'application/json'
//   }
// }
// export const defaultCurrency = "GNF";
// export const locale = "en-FR";

export const config = {
  apiUrl: process.env.REACT_APP_API_URL || "http://localhost",
  headers: {
    "Content-Type": "application/json",
  },
};
export const defaultCurrency: string = process.env.REACT_APP_CURRENCY || "USD";
export const locale: string = process.env.REACT_APP_LOCALE || "en-US";
export const appVersion: string = process.env.REACT_APP_VERSION || "0";
