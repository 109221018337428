import { actionTypes } from './OrdersAction';

const initialState = {
    isFetching: false,
    order: null,
    orders: [],
    transactions: [],
    products: [],
    warehouses: [],
    lots: [],
    error: null
}

export const orders = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.ORDER_FETCH:
            return {
                ...state,
                isFetching: action.isFetching,
            }
        case actionTypes.ORDER_SYNC:
            return {
                ...state,
                isFetching: false,
                order: action.order,
                error: null
            }
        case actionTypes.ORDERS_SYNC:
            return {
                ...state,
                isFetching: false,
                orders: action.orders,
                error: null
            }
        case actionTypes.TRANSACTIONS_SYNC:
            return {
                ...state,
                isFetching: false,
                transactions: action.transactions,
                error: null
            }
        case actionTypes.PRODUCTS_SYNC:
            return {
                ...state,
                isFetching: false,
                products: action.products,
                error: null
            }
        case actionTypes.WAREHOUSES_SYNC:
            return {
                ...state,
                isFetching: false,
                warehouses: action.warehouses,
                error: null
            }
        case actionTypes.LOTS_SYNC:
            return {
                ...state,
                isFetching: false,
                lots: action.lots,
                error: null
            }
        case actionTypes.ORDER_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
        default:
            return state;
    }
}