import axios from 'axios';
import { config } from '../../config';
import { batch } from 'react-redux'
import { alertService } from '../../_services';
/**
 * Suppliers list
 */
export const actionTypes = {
    DASHBOARD_FETCH: 'dashboardFetch',
    STATS_SYNC: 'statsSync',
    SALES_SYNC: 'salesSync',
    DASHBOARD_ERROR: 'dashboardError',
}


export const dashboardFetch = (isFetching: boolean) => {
    return {
        type: actionTypes.DASHBOARD_FETCH,
        isFetching,
    }
}

export const statsSync = (stats: any) => {
    return {
        type: actionTypes.STATS_SYNC,
        stats
    }
}

export const salesSync = (sales: any) => {
    return {
        type: actionTypes.SALES_SYNC,
        sales
    }
}


export const supplierError = (error: Error) => {
    return {
        type: actionTypes.DASHBOARD_ERROR,
        error,
    }
}

export const clearStats = () => ((dispatch) => {
    dispatch(statsSync(null))
});

export const getStats = () => ((dispatch, getState) => {
    dispatch(dashboardFetch(true));

    const hconfig = {
        headers: { Authorization: `Bearer ${getState().Auth.token}` }
    }

    axios.get(`${config.apiUrl}/warehouses/stats/summary`, hconfig)
        .then(res => {
            dispatch(statsSync(res.data))
        })
        .catch(error => {
            console.log("error", error)
            dispatch(supplierError(error.message));
        });
});


export const getSalesByDay = () => ((dispatch, getState) => {
    dispatch(dashboardFetch(true));

    const hconfig = {
        headers: { Authorization: `Bearer ${getState().Auth.token}` }
    }

    axios.get(`${config.apiUrl}/warehouses/stats/sales`, hconfig)
        .then(res => {
            dispatch(salesSync(res.data))
        })
        .catch(error => {
            console.log("error", error)
            dispatch(supplierError(error.message));
        });
});
