import axios from "axios";
import { config } from "../../config";
import { batch } from "react-redux";
import { authUserSync, logout } from "../login/AuthAction";
import { alertService } from "../../_services";
/**
 * Users list
 */
export const actionTypes = {
  USER_FETCH: "userFetch",
  USER_SYNC: "userSync",
  USERS_SYNC: "usersSync",
  USER_ERROR: "userError",
};

export const userRoles = [
  { value: "admin", label: "Administrator" },
  { value: "manager", label: "Manager" },
];

export const userFetch = (isFetching: boolean) => {
  return {
    type: actionTypes.USER_FETCH,
    isFetching,
  };
};

export const userSync = (user: any) => {
  return {
    type: actionTypes.USER_SYNC,
    user,
  };
};

export const usersSync = (users: any[]) => {
  return {
    type: actionTypes.USERS_SYNC,
    users,
  };
};

export const userError = (error: Error) => {
  return {
    type: actionTypes.USER_ERROR,
    error,
  };
};

export const clearUser = () => (dispatch) => {
  dispatch(userSync(null));
};

export const getUsers = () => (dispatch, getState) => {
  dispatch(userFetch(true));

  const hconfig = {
    headers: { Authorization: `Bearer ${getState().Auth.token}` },
  };

  axios
    .get(`${config.apiUrl}/users`, hconfig)
    .then((res) => {
      dispatch(usersSync(res.data.users));
    })
    .catch((error) => {
      console.log("error", error);
      dispatch(userError(error.message));
    });
};

export const getMe = (history?: any) => (dispatch, getState) => {
  dispatch(userFetch(true));

  const hconfig = {
    headers: { Authorization: `Bearer ${getState().Auth.token}` },
  };

  axios
    .get(`${config.apiUrl}/users/me`, hconfig)
    .then((res) => {
      batch(() => {
        dispatch(userSync(res.data));
        dispatch(authUserSync(res.data));
      });
    })
    .catch((error) => {
      console.log("error", error);
      dispatch(userError(error.message));
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 500)
      ) {
        dispatch(logout(history));
      }
    });
};

export const getUser = (id) => (dispatch, getState) => {
  dispatch(userFetch(true));

  const hconfig = {
    headers: { Authorization: `Bearer ${getState().Auth.token}` },
  };

  axios
    .get(`${config.apiUrl}/users/${id}`, hconfig)
    .then((res) => {
      dispatch(userSync(res.data));
    })
    .catch((error) => {
      console.log("error", error);
      dispatch(userError(error.message));
    });
};

export const createUser = (data: any, history: any) => (dispatch, getState) => {
  dispatch(userFetch(true));

  const hconfig = {
    headers: { Authorization: `Bearer ${getState().Auth.token}` },
  };

  axios
    .post(`${config.apiUrl}/users`, data, hconfig)
    .then((res) => {
      dispatch(getUsers());
    })
    .catch((error) => {
      dispatch(userFetch(false));
      console.log("error", error);

      if (error.response.data.message) {
        alertService.error("Oops! " + error.response.data.message, {
          autoClose: true,
        });
      } else {
        alertService.error(
          "Oops! Something went wrong, please try again later.",
          { autoClose: true }
        );
      }
    });
};

export const updateUser = (id, data) => (dispatch, getState) => {
  dispatch(userFetch(true));

  const hconfig = {
    headers: { Authorization: `Bearer ${getState().Auth.token}` },
  };

  axios
    .put(`${config.apiUrl}/users/${id}`, data, hconfig)
    .then((res) => {
      batch(() => {
        dispatch(userSync(res.data));
        if (id === "me") {
          dispatch(authUserSync(res.data));
        }
        alertService.success("User successfully updated", { autoClose: true });
      });
    })
    .catch((error) => {
      dispatch(userFetch(false));
      console.log("error", error);

      if (error.response.data.message) {
        alertService.error("Oops! " + error.response.data.message, {
          autoClose: true,
        });
      } else {
        alertService.error(
          "Oops! Something went wrong, please try again later.",
          { autoClose: true }
        );
      }
    });
};

export const deleteUser = (id, history) => (dispatch, getState) => {
  dispatch(userFetch(true));

  const hconfig = {
    headers: { Authorization: `Bearer ${getState().Auth.token}` },
  };

  axios
    .delete(`${config.apiUrl}/users/${id}`, hconfig)
    .then((res) => {
      history.push(`/users`);
    })
    .catch((error) => {
      dispatch(userFetch(false));
      console.log("error", error);

      if (error.response.data.message) {
        alertService.error("Oops! " + error.response.data.message, {
          autoClose: true,
        });
      } else {
        alertService.error(
          "Oops! Something went wrong, please try again later.",
          { autoClose: true }
        );
      }
    });
};
