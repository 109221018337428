import moment from 'moment';
import { actionTypes } from './DashboardAction';

const initialState = {
    isFetching: false,
    stats: {},
    salesChartData: [],
    error: null
}

export const dashboard = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.DASHBOARD_FETCH:
            return {
                ...state,
                isFetching: action.isFetching,
            }
        case actionTypes.STATS_SYNC:
            return {
                ...state,
                isFetching: false,
                stats: action.stats,
                error: null
            }
        case actionTypes.SALES_SYNC:

            const lastThirtyDays = [...new Array(30)].map((i, idx) => moment().startOf("day").subtract(idx, "days")).reverse();
            const labels:string[] = []
            const values:number[] = []
            lastThirtyDays.forEach((value, key) => {
                const dateString = value.format("YYYY-MM-DD")
                const valueData = (action.sales as any[]).find(s => s.date === dateString)
                labels.push(value.format("MMM Do"))
                values.push(valueData ? valueData.total : 0)
            })

            const salesChartData = {
                labels: labels,
                    datasets: [
                        {
                            label: '# of sales',
                            fill: false,
                            lineTension: 0.1,
                            backgroundColor: 'rgba(75,192,192,0.4)',
                            borderColor: 'rgba(75,192,192,1)',
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: 'rgba(75,192,192,1)',
                            pointBackgroundColor: '#fff',
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                            pointHoverBorderColor: 'rgba(220,220,220,1)',
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: values
                        }
                    ]
            }
            
            return {
                ...state,
                isFetching: false,
                salesChartData: salesChartData,
                error: null
            }
        case actionTypes.DASHBOARD_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
        default:
            return state;
    }
}