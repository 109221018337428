import axios from "axios";
import { config } from "../../config";
import { batch } from "react-redux";
import { alertService } from "../../_services";
/**
 * Suppliers list
 */
export const actionTypes = {
  PRODUCT_FETCH: "supplierFetch",
  PRODUCT_SYNC: "supplierSync",
  PRODUCTS_SYNC: "suppliersSync",
  PRODUCT_ERROR: "supplierError",
};

export const supplierFetch = (isFetching: boolean) => {
  return {
    type: actionTypes.PRODUCT_FETCH,
    isFetching,
  };
};

export const supplierSync = (supplier: any) => {
  return {
    type: actionTypes.PRODUCT_SYNC,
    supplier,
  };
};

export const suppliersSync = (suppliers: any[]) => {
  return {
    type: actionTypes.PRODUCTS_SYNC,
    suppliers,
  };
};

export const supplierError = (error: Error) => {
  return {
    type: actionTypes.PRODUCT_ERROR,
    error,
  };
};

export const clearSupplier = () => (dispatch) => {
  dispatch(supplierSync(null));
};

export const getSuppliers = () => (dispatch, getState) => {
  dispatch(supplierFetch(true));

  const hconfig = {
    headers: { Authorization: `Bearer ${getState().Auth.token}` },
  };

  axios
    .get(`${config.apiUrl}/suppliers`, hconfig)
    .then((res) => {
      dispatch(suppliersSync(res.data.suppliers));
    })
    .catch((error) => {
      console.log("error", error);
      dispatch(supplierError(error.message));
    });
};

export const getSupplier = (id) => (dispatch, getState) => {
  dispatch(supplierFetch(true));

  const hconfig = {
    headers: { Authorization: `Bearer ${getState().Auth.token}` },
  };

  axios
    .get(`${config.apiUrl}/suppliers/${id}`, hconfig)
    .then((res) => {
      dispatch(supplierSync(res.data));
    })
    .catch((error) => {
      console.log("error", error);
      dispatch(supplierError(error.message));
    });
};

export const createSupplier = (data: any, history: any) => (
  dispatch,
  getState
) => {
  dispatch(supplierFetch(true));

  const hconfig = {
    headers: { Authorization: `Bearer ${getState().Auth.token}` },
  };

  axios
    .post(`${config.apiUrl}/suppliers`, data, hconfig)
    .then((res) => {
      history.push(`/suppliers/${res.data.id}`);
    })
    .catch((error) => {
      dispatch(supplierFetch(false));
      console.log("error", error);

      if (error.response.data.message) {
        alertService.error("Oops! " + error.response.data.message, {
          autoClose: true,
        });
      } else {
        alertService.error(
          "Oops! Something went wrong, please try again later.",
          { autoClose: true }
        );
      }
    });
};

export const updateSupplier = (id, data) => (dispatch, getState) => {
  dispatch(supplierFetch(true));

  const hconfig = {
    headers: { Authorization: `Bearer ${getState().Auth.token}` },
  };

  axios
    .put(`${config.apiUrl}/suppliers/${id}`, data, hconfig)
    .then((res) => {
      dispatch(supplierSync(res.data));
      alertService.success("Supplier successfully updated", {
        autoClose: true,
      });
    })
    .catch((error) => {
      dispatch(supplierFetch(false));
      console.log("error", error);

      if (error.response.data.message) {
        alertService.error("Oops! " + error.response.data.message, {
          autoClose: true,
        });
      } else {
        alertService.error(
          "Oops! Something went wrong, please try again later.",
          { autoClose: true }
        );
      }
    });
};

export const deleteSupplier = (id, history) => (dispatch, getState) => {
  dispatch(supplierFetch(true));

  const hconfig = {
    headers: { Authorization: `Bearer ${getState().Auth.token}` },
  };

  axios
    .delete(`${config.apiUrl}/suppliers/${id}`, hconfig)
    .then((res) => {
      history.push(`/suppliers`);
    })
    .catch((error) => {
      dispatch(supplierFetch(false));
      console.log("error", error);

      if (error.response.data.message) {
        alertService.error("Oops! " + error.response.data.message, {
          autoClose: true,
        });
      } else {
        alertService.error(
          "Oops! Something went wrong, please try again later.",
          { autoClose: true }
        );
      }
    });
};
