import axios from "axios";
import { config } from "../../config";
import { batch } from "react-redux";
import { alertService } from "../../_services";
/**
 * Products list
 */
export const actionTypes = {
  PRODUCT_FETCH: "productFetch",
  PRODUCT_SYNC: "productSync",
  PRODUCTS_SYNC: "productsSync",
  PRODUCT_ERROR: "productError",
};

const AlertAutoClose = false;
const AlertKeepAfterRouteChange = false;

export const productFetch = (isFetching: boolean) => {
  return {
    type: actionTypes.PRODUCT_FETCH,
    isFetching,
  };
};

export const productSync = (product: any) => {
  return {
    type: actionTypes.PRODUCT_SYNC,
    product,
  };
};

export const productsSync = (products: any[]) => {
  return {
    type: actionTypes.PRODUCTS_SYNC,
    products,
  };
};

export const productError = (error: Error) => {
  return {
    type: actionTypes.PRODUCT_ERROR,
    error,
  };
};

export const clearProduct = () => (dispatch, getState) => {
  dispatch(productSync(null));
};

export const getProducts = () => (dispatch, getState) => {
  dispatch(productFetch(true));

  const hconfig = {
    headers: { Authorization: `Bearer ${getState().Auth.token}` },
  };

  axios
    .get(`${config.apiUrl}/products`, hconfig)
    .then((res) => {
      dispatch(productsSync(res.data.products));
    })
    .catch((error) => {
      console.log("error", error);
      dispatch(productError(error.message));
    });
};

export const getProduct = (id) => (dispatch, getState) => {
  dispatch(productFetch(true));

  const hconfig = {
    headers: { Authorization: `Bearer ${getState().Auth.token}` },
  };

  axios
    .get(`${config.apiUrl}/products/${id}`, hconfig)
    .then((res) => {
      dispatch(productSync(res.data));
    })
    .catch((error) => {
      console.log("error", error);
      dispatch(productError(error.message));
    });
};

export const createProduct = (data: any, history: any) => (
  dispatch,
  getState
) => {
  dispatch(productFetch(true));

  const hconfig = {
    headers: { Authorization: `Bearer ${getState().Auth.token}` },
  };

  axios
    .post(`${config.apiUrl}/products`, data, hconfig)
    .then((res) => {
      history.push(`/products/${res.data.id}`);
    })
    .catch((error) => {
      dispatch(productFetch(false));
      console.log("error", error);

      if (error.response.data.message) {
        alertService.error("Oops! " + error.response.data.message, {
          AlertAutoClose,
          AlertKeepAfterRouteChange,
        });
      } else {
        alertService.error(
          "Oops! Something went wrong, please try again later.",
          { AlertAutoClose, AlertKeepAfterRouteChange }
        );
      }
    });
};

export const updateProduct = (id, data) => (dispatch, getState) => {
  dispatch(productFetch(true));

  const hconfig = {
    headers: { Authorization: `Bearer ${getState().Auth.token}` },
  };

  axios
    .put(`${config.apiUrl}/products/${id}`, data, hconfig)
    .then((res) => {
      dispatch(productSync(res.data));
      alertService.success("Product successfully updated", { autoClose: true });
    })
    .catch((error) => {
      dispatch(productFetch(false));
      console.log("error", error);

      if (error.response.data.message) {
        alertService.error("Oops! " + error.response.data.message, {
          AlertAutoClose,
          AlertKeepAfterRouteChange,
        });
      } else {
        alertService.error(
          "Oops! Something went wrong, please try again later.",
          { AlertAutoClose, AlertKeepAfterRouteChange }
        );
      }
    });
};

export const deleteProduct = (id, history) => (dispatch, getState) => {
  dispatch(productFetch(true));

  const hconfig = {
    headers: { Authorization: `Bearer ${getState().Auth.token}` },
  };

  axios
    .delete(`${config.apiUrl}/products/${id}`, hconfig)
    .then((res) => {
      history.push(`/products`);
    })
    .catch((error) => {
      dispatch(productFetch(false));
      console.log("error", error);

      if (error.response.data.message) {
        alertService.error("Oops! " + error.response.data.message, {
          AlertAutoClose,
          AlertKeepAfterRouteChange,
        });
      } else {
        alertService.error(
          "Oops! Something went wrong, please try again later.",
          { AlertAutoClose, AlertKeepAfterRouteChange }
        );
      }
    });
};

export const deleteVariant = (productId, id) => (dispatch, getState) => {
  dispatch(productFetch(true));

  const hconfig = {
    headers: { Authorization: `Bearer ${getState().Auth.token}` },
  };

  axios
    .delete(`${config.apiUrl}/products/${productId}/variants/${id}`, hconfig)
    .then((res) => {
      dispatch(getProduct(productId));
    })
    .catch((error) => {
      dispatch(productFetch(false));
      console.log("error", error);

      if (error.response.data.message) {
        alertService.error("Oops! " + error.response.data.message, {
          AlertAutoClose,
          AlertKeepAfterRouteChange,
        });
      } else {
        alertService.error(
          "Oops! Something went wrong, please try again later.",
          { AlertAutoClose, AlertKeepAfterRouteChange }
        );
      }
    });
};
