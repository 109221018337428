import 'whatwg-fetch';
import { config } from '../../config';
import axios from 'axios';
import { alertService } from '../../_services';

export const actionTypes = {
	SET_REDIRECT_URL: 'setRedirectUrl',
	AUTH_FETCH: 'authFetch',
	LOGIN_SYNC: 'loginSync',
	LOGOUT_SYNC: 'logoutSync',
	AUTH_USER_SYNC: 'authUserSync',
};

export const setRedirectUrl = redirectUrl => ({
	type: 'setRedirectUrl',
	redirectUrl,
});

const authFetch = isFetching => ({
	type: actionTypes.AUTH_FETCH,
	isFetching,
});

const loginSync = (token, user) => ({
	type: actionTypes.LOGIN_SYNC,
	token,
	user,
});

export const authUserSync = (user) => ({
	type: actionTypes.AUTH_USER_SYNC,
	user,
});

const logoutSync = () => ({
	type: actionTypes.LOGOUT_SYNC,
});

export const login = (data, history) => ((dispatch) => {
	dispatch(authFetch(true));
	let configHeaders:any = {
		'Content-Type': 'application/json',
	}

	axios.post(`${config.apiUrl}/auth/login`, data, configHeaders)
		.then(res => {
			dispatch(loginSync(res.data.token, res.data));
			setTimeout(function () {
				history.push("dashboard");
			}, 500);
		})
		.catch(error => {
			alertService.error('Invalid username or password', {autoClose: true})

			dispatch(authFetch(false));
			console.log("error", error)
		});
});

export const logout = (history?: any) => ((dispatch) => {
	sessionStorage.clear()
	dispatch(logoutSync());
	if(history) history.push("/login");
	
});

