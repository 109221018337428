import axios from 'axios';
import { config } from '../../config';
import { batch } from 'react-redux'
import qs from 'querystring'
import { alertService } from '../../_services';

/**
 * Orders list
 */
export const actionTypes = {
    ORDER_FETCH: 'orderFetch',
    ORDER_SYNC: 'orderSync',
    ORDERS_SYNC: 'ordersSync',
    TRANSACTIONS_SYNC: 'transactionsSync',
    PRODUCTS_SYNC: 'productsSync',
    WAREHOUSES_SYNC: 'warehousesSync',
    LOTS_SYNC: 'lotsSync',
    ORDER_ERROR: 'orderError',
}

export const orderStatusOptions = [
    { value: '', label: 'All' },
    { value: "pending", label: 'En attente' },
    { value: "processed", label: 'En traitement' },
    { value: "completed", label: 'Completé' },
    { value: "canceled", label: 'Annuler' },
];


export const orderFetch = (isFetching: boolean) => {
    return {
        type: actionTypes.ORDER_FETCH,
        isFetching,
    }
}

export const orderSync = (order: any) => {
    return {
        type: actionTypes.ORDER_SYNC,
        order
    }
}

export const ordersSync = (orders: any[]) => {
    return {
        type: actionTypes.ORDERS_SYNC,
        orders
    }
}
export const transactionsSync = (transactions: any[]) => {
    return {
        type: actionTypes.TRANSACTIONS_SYNC,
        transactions
    }
}

export const productsSync = (products: any[]) => {
    return {
        type: actionTypes.PRODUCTS_SYNC,
        products
    }
}

export const warehousesSync = (warehouses: any[]) => {
    return {
        type: actionTypes.WAREHOUSES_SYNC,
        warehouses
    }
}

export const lotsSync = (lots: any[]) => {
    return {
        type: actionTypes.LOTS_SYNC,
        lots
    }
}


export const orderError = (error: Error) => {
    return {
        type: actionTypes.ORDER_ERROR,
        error,
    }
}

export const clearOrder = () => ((dispatch) => {
    dispatch(orderSync(null))
});

export const getOrders = (filter: any = {}) => ((dispatch, getState) => {
    dispatch(orderFetch(true));

    const hconfig = {
        headers: { Authorization: `Bearer ${getState().Auth.token}` }
    }
    const query = qs.stringify(filter);

    axios.get(`${config.apiUrl}/orders?${query}`, hconfig)
        .then(res => {
            dispatch(ordersSync(res.data.orders))
        })
        .catch(error => {
            console.log("error", error)
            dispatch(orderError(error.message));
        });
});

export const getOrder = (id) => ((dispatch, getState) => {
    dispatch(orderFetch(true));

    const hconfig = {
        headers: { Authorization: `Bearer ${getState().Auth.token}` }
    }

    axios.get(`${config.apiUrl}/orders/${id}`, hconfig)
        .then(res => {
            dispatch(orderSync(res.data))
        })
        .catch(error => {
            console.log("error", error)
            dispatch(orderError(error.message));
        });

});

export const createOrder = (data: any, history: any) => ((dispatch, getState) => {
    dispatch(orderFetch(true))

    const hconfig = {
        headers: { Authorization: `Bearer ${getState().Auth.token}` }
    }

    axios.post(`${config.apiUrl}/orders`, data, hconfig)
        .then(res => {
            history.push(`/orders/${res.data.id}`)

        })
        .catch(error => {
            dispatch(orderError(error.message))
            console.log("error", error)

            if (error.response.data.message) {
                alertService.error('Oops! ' + error.response.data.message, {autoClose: true})
            }
            else {
                alertService.error('Oops! Something went wrong, please try again later.', {autoClose: true})
            }
        });
});

export const updateOrder = (id, data) => ((dispatch, getState) => {
    dispatch(orderFetch(true))

    const hconfig = {
        headers: { Authorization: `Bearer ${getState().Auth.token}` }
    }

    axios.put(`${config.apiUrl}/orders/${id}`, data, hconfig)
        .then(res => {
            dispatch(orderSync(res.data))
            alertService.success("Order successfully updated", {autoClose: true})

        })
        .catch(error => {
            dispatch(orderError(error.message))
            console.log("error", error)

            if (error.response.data.message) {
                alertService.error('Oops! ' + error.response.data.message, {autoClose: true})
            }
            else {
                alertService.error('Oops! Something went wrong, please try again later.', {autoClose: true})
            }
        });

});

export const updateStatus = (id, status) => ((dispatch, getState) => {
    dispatch(orderFetch(true))

    const hconfig = {
        headers: { Authorization: `Bearer ${getState().Auth.token}` }
    }

    axios.put(`${config.apiUrl}/orders/${id}/status`, {status : status}, hconfig)
        .then(res => {
            dispatch(orderSync(res.data))
            alertService.success(`Order successfully ${status}`, {autoClose: true})

        })
        .catch(error => {
            dispatch(orderError(error.message))
            console.log("error", error)

            if (error.response.data.message) {
                alertService.error('Oops! ' + error.response.data.message, {autoClose: true})
            }
            else {
                alertService.error('Oops! Something went wrong, please try again later.', {autoClose: true})
            }
        });
});

export const deleteOrder = (id, history) => ((dispatch, getState) => {
    dispatch(orderFetch(true))

    const hconfig = {
        headers: { Authorization: `Bearer ${getState().Auth.token}` }
    }

    axios.delete(`${config.apiUrl}/orders/${id}`, hconfig)
        .then(res => {
            history.push(`/orders`)
        })
        .catch(error => {
            dispatch(orderError(error.message))
            console.log("error", error)

            if (error.response.data.message) {
                alertService.error('Oops! ' + error.response.data.message, {autoClose: true})
            }
            else {
                alertService.error('Oops! Something went wrong, please try again later.', {autoClose: true})
            }
        });

});


export const getTransactions = (orderId: string) => ((dispatch, getState) => {
    dispatch(orderFetch(true));

    const hconfig = {
        headers: { Authorization: `Bearer ${getState().Auth.token}` }
    }
    
    axios.get(`${config.apiUrl}/orders/${orderId}/transactions`, hconfig)
        .then(res => {
            dispatch(transactionsSync(res.data.transactions))
        })
        .catch(error => {
            console.log("error", error)
            dispatch(orderError(error.message));
        });
});


export const createTransaction = (orderId: string, data: any) => ((dispatch, getState) => {
    dispatch(orderFetch(true))

    const hconfig = {
        headers: { Authorization: `Bearer ${getState().Auth.token}` }
    }

    axios.post(`${config.apiUrl}/orders/${orderId}/transactions`, data, hconfig)
        .then(res => {
            dispatch(getTransactions(orderId))
            dispatch(getOrder(orderId))
            alertService.success("Transaction successfully created", {autoClose: true})
        })
        .catch(error => {
            dispatch(orderError(error.message))
            console.log("error", error)

            if (error.response.data.message) {
                alertService.error('Oops! ' + error.response.data.message, {autoClose: true})
            }
            else {
                alertService.error('Oops! Something went wrong, please try again later.', {autoClose: true})
            }
        });
});

export const updateTransaction = (orderId: string, id, data) => ((dispatch, getState) => {
    dispatch(orderFetch(true))

    const hconfig = {
        headers: { Authorization: `Bearer ${getState().Auth.token}` }
    }

    axios.put(`${config.apiUrl}/orders/${orderId}/transactions/${id}`, data, hconfig)
        .then(res => {
            dispatch(getTransactions(orderId))
            dispatch(getOrder(orderId))
            alertService.success("Transaction successfully updated", {autoClose: true})
        })
        .catch(error => {
            dispatch(orderError(error.message))
            console.log("error", error)

            if (error.response.data.message) {
                alertService.error('Oops! ' + error.response.data.message, {autoClose: true})
            }
            else {
                alertService.error('Oops! Something went wrong, please try again later.', {autoClose: true})
            }
        });
});


export const getProducts = () => ((dispatch, getState) => {
    dispatch(orderFetch(true));

    const hconfig = {
        headers: { Authorization: `Bearer ${getState().Auth.token}` }
    }

    axios.get(`${config.apiUrl}/products`, hconfig)
        .then(res => {
            dispatch(productsSync(res.data.products))
        })
        .catch(error => {
            console.log("error", error)
            dispatch(orderError(error.message));
        });

});

export const getWarehouses = () => ((dispatch, getState) => {
    dispatch(orderFetch(true));

    const hconfig = {
        headers: { Authorization: `Bearer ${getState().Auth.token}` }
    }

    axios.get(`${config.apiUrl}/warehouses`, hconfig)
        .then(res => {
            dispatch(warehousesSync(res.data.warehouses))
        })
        .catch(error => {
            console.log("error", error)
            dispatch(orderError(error.message));
        });
});

export const getLots = (warehouseId: string) => ((dispatch, getState) => {
    dispatch(orderFetch(true));

    const hconfig = {
        headers: { Authorization: `Bearer ${getState().Auth.token}` }
    }

    axios.get(`${config.apiUrl}/warehouses/${warehouseId}/lots`, hconfig)
        .then(res => {
            dispatch(lotsSync(res.data))
        })
        .catch(error => {
            console.log("error", error)
            dispatch(orderError(error.message));
        });
});