import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { connect } from "react-redux";

import './scss/style.scss';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';


import { getMe } from './views/users/UsersAction';


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Home = React.lazy(() => import('./views/pages/Home'));
const Login = React.lazy(() => import('./views/login/Login'));
const Page404 = React.lazy(() => import('./views/pages/Page404'));
const Page500 = React.lazy(() => import('./views/pages/Page500'));

interface IProps {
  history?: any
  match?: any
  location?: any
}

class App extends React.Component<IProps, {}> {

  render() {

    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" render={props => <Login {...props} />} />
            <Route exact path="/404" render={props => <Page404 {...props} />} />
            <Route exact path="/500" render={props => <Page500 {...props} />} />
            <Route path="/" render={props => <TheLayout {...props} />} />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}



export default App;
