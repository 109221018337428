import axios from 'axios';
import { config } from '../../config';
import { batch } from 'react-redux'
import qs from 'querystring'
import { alertService } from '../../_services';

/**
 * PurchaseOrders list
 */
export const actionTypes = {
    ORDER_FETCH: 'purchaseOrderFetch',
    ORDER_SYNC: 'purchaseOrderSync',
    ORDERS_SYNC: 'purchaseOrdersSync',
    PRODUCTS_SYNC: 'productsSync',
    SUPPLIERS_SYNC: 'suppliersSync',
    ORDER_ERROR: 'purchaseOrderError',
}

export const purchaseOrderFetch = (isFetching: boolean) => {
    return {
        type: actionTypes.ORDER_FETCH,
        isFetching,
    }
}

export const purchaseOrderSync = (purchaseOrder: any) => {
    return {
        type: actionTypes.ORDER_SYNC,
        purchaseOrder
    }
}

export const purchaseOrdersSync = (purchaseOrders: any[]) => {
    return {
        type: actionTypes.ORDERS_SYNC,
        purchaseOrders
    }
}

export const productsSync = (products: any[]) => {
    return {
        type: actionTypes.PRODUCTS_SYNC,
        products
    }
}

export const suppliersSync = (suppliers: any[]) => {
    return {
        type: actionTypes.SUPPLIERS_SYNC,
        suppliers
    }
}

export const purchaseOrderError = (error: Error) => {
    return {
        type: actionTypes.ORDER_ERROR,
        error,
    }
}

export const clearPurchaseOrder = () => ((dispatch) => {
    dispatch(purchaseOrderSync(null))
});

export const getPurchaseOrders = (filter: any = {}) => ((dispatch, getState) => {
    dispatch(purchaseOrderFetch(true));

    const hconfig = {
        headers: { Authorization: `Bearer ${getState().Auth.token}` }
    }
    const query = qs.stringify(filter);

    axios.get(`${config.apiUrl}/purchaseorders?${query}`, hconfig)
        .then(res => {
            dispatch(purchaseOrdersSync(res.data.orders))
        })
        .catch(error => {
            console.log("error", error)
            dispatch(purchaseOrderError(error.message));
        });
});

export const getPurchaseOrder = (id) => ((dispatch, getState) => {
    dispatch(purchaseOrderFetch(true));

    const hconfig = {
        headers: { Authorization: `Bearer ${getState().Auth.token}` }
    }

    axios.get(`${config.apiUrl}/purchaseorders/${id}`, hconfig)
        .then(res => {
            dispatch(purchaseOrderSync(res.data))
        })
        .catch(error => {
            console.log("error", error)
            dispatch(purchaseOrderError(error.message));
        });

});

export const createPurchaseOrder = (data: any, history: any) => ((dispatch, getState) => {
    dispatch(purchaseOrderFetch(true))

    const hconfig = {
        headers: { Authorization: `Bearer ${getState().Auth.token}` }
    }

    axios.post(`${config.apiUrl}/purchaseorders`, data, hconfig)
        .then(res => {
            history.push(`/purchase-orders/${res.data.id}`)
        })
        .catch(error => {
            dispatch(purchaseOrderFetch(false))
            console.log("error", error)

            if (error.response.data.message) {
                alertService.error('Oops! ' + error.response.data.message, {autoClose: true})
            }
            else {
                alertService.error('Oops! Something went wrong, please try again later.', {autoClose: true})
            }
        });
});

export const updatePurchaseOrder = (id, data) => ((dispatch, getState) => {
    dispatch(purchaseOrderFetch(true))

    const hconfig = {
        headers: { Authorization: `Bearer ${getState().Auth.token}` }
    }

    axios.put(`${config.apiUrl}/purchaseorders/${id}`, data, hconfig)
        .then(res => {
            dispatch(purchaseOrderSync(res.data))
            alertService.success("Purchase Order successfully updated", {autoClose: true})

        })
        .catch(error => {
            dispatch(purchaseOrderFetch(false))
            console.log("error", error)

            if (error.response.data.message) {
                alertService.error('Oops! ' + error.response.data.message, {autoClose: true})
            }
            else {
                alertService.error('Oops! Something went wrong, please try again later.', {autoClose: true})
            }
        });

});

export const updateStatus = (id, status) => ((dispatch, getState) => {
    dispatch(purchaseOrderFetch(true))

    const hconfig = {
        headers: { Authorization: `Bearer ${getState().Auth.token}` }
    }

    axios.put(`${config.apiUrl}/purchaseorders/${id}/status`, {status : status}, hconfig)
        .then(res => {
            dispatch(purchaseOrderSync(res.data))
            alertService.success(`Purchase Order successfully ${status}`, {autoClose: true})

        })
        .catch(error => {
            dispatch(purchaseOrderFetch(false))
            console.log("error", error)

            if (error.response.data.message) {
                alertService.error('Oops! ' + error.response.data.message, {autoClose: true})
            }
            else {
                alertService.error('Oops! Something went wrong, please try again later.', {autoClose: true})
            }
        });

});

export const deletePurchaseOrder = (id, history) => ((dispatch, getState) => {
    dispatch(purchaseOrderFetch(true))

    const hconfig = {
        headers: { Authorization: `Bearer ${getState().Auth.token}` }
    }

    axios.delete(`${config.apiUrl}/purchaseorders/${id}`, hconfig)
        .then(res => {
            history.push(`/purchaseOrders`)
        })
        .catch(error => {
            dispatch(purchaseOrderFetch(false))
            console.log("error", error)

            if (error.response.data.message) {
                alertService.error('Oops! ' + error.response.data.message, {autoClose: true})
            }
            else {
                alertService.error('Oops! Something went wrong, please try again later.', {autoClose: true})
            }
        });

});

export const getProducts = () => ((dispatch, getState) => {
    dispatch(purchaseOrderFetch(true));

    const hconfig = {
        headers: { Authorization: `Bearer ${getState().Auth.token}` }
    }

    axios.get(`${config.apiUrl}/products`, hconfig)
        .then(res => {
            dispatch(productsSync(res.data.products))
        })
        .catch(error => {
            console.log("error", error)
            dispatch(purchaseOrderError(error.message));
        });

});


export const getSuppliers = () => ((dispatch, getState) => {
    dispatch(purchaseOrderFetch(true));

    const hconfig = {
        headers: { Authorization: `Bearer ${getState().Auth.token}` }
    }

    axios.get(`${config.apiUrl}/suppliers`, hconfig)
        .then(res => {
            dispatch(suppliersSync(res.data.suppliers))
        })
        .catch(error => {
            console.log("error", error)
            dispatch(purchaseOrderError(error.message));
        });

});
