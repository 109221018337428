import { actionTypes, inventorySync } from './WarehousesAction';

const initialState = {
    isFetching: false,
    warehouse: null,
    warehouses: [],
    products: [],
    stockEntries: [],
    lots: [],
    inventory: [],
    error: null
}

export const warehouses = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.WAREHOUSE_FETCH:
            return {
                ...state,
                isFetching: action.isFetching,
            }
        case actionTypes.WAREHOUSE_SYNC:
            return {
                ...state,
                isFetching: false,
                warehouse: action.warehouse,
                error: null
            }
        case actionTypes.WAREHOUSES_SYNC:
            return {
                ...state,
                isFetching: false,
                warehouses: action.warehouses,
                error: null
            }
        case actionTypes.STOCKENTRIES_SYNC:
            return {
                ...state,
                isFetching: false,
                stockEntries: action.stockEntries,
                error: null
            }
        case actionTypes.PRODUCTS_SYNC:
            return {
                ...state,
                isFetching: false,
                products: action.products,
                error: null
            }
        case actionTypes.LOTS_SYNC:
            return {
                ...state,
                isFetching: false,
                lots: action.lots,
                error: null
            }
        case actionTypes.INVENTORY_SYNC:
            return {
                ...state,
                isFetching: false,
                inventory: action.inventory,
                error: null
            }
        case actionTypes.WAREHOUSE_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
        default:
            return state;
    }
}